@import "../../assets/style/theme.scss";

.loadingBox {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  width: 8px;
  height: 15px;
  background-color: $theme-color;
  float: left;
  margin: 0 3px;
  animation: loading linear 1s infinite;
  -webkit-animation: loading linear 1s infinite;
}
.loading:nth-child(1) {
  animation-delay: 0s;
}
.loading:nth-child(2) {
  animation-delay: 0.15s;
}
.loading:nth-child(3) {
  animation-delay: 0.3s;
}
.loading:nth-child(4) {
  animation-delay: 0.45s;
}
.loading:nth-child(5) {
  animation-delay: 0.6s;
}
@keyframes loading {
  0%,
  60%,
  100% {
    transform: scale(1);
  }
  30% {
    transform: scaleY(3);
  }
}
