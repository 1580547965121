@import 'theme.scss';
@import 'qualityReport.scss';

body {
  font-size: $font-size;
}

// * {
//   font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
//     Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
//     SimSun, sans-serif;
// }

.button {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid #c9cacb;
  box-sizing: border-box;
  cursor: pointer;
}

.button:hover {
  background: #4c7ff5;
  color: white;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.bg-white {
  background-color: white;
}

.color-main {
  color: $theme-color;
}

.color-black {
  color: #000;
}

.color-red {
  color: #f00;
}

.font-bold {
  font-weight: bold;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.inline-block {
  display: inline-block;
}

.router-name {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}

.link-sm {
  font-size: 12px;
  height: auto;
  padding: 0;
}

/**---------- 测试用户添加水印 --------------**/
.test-user {
  background-image: url(../images/sy1.png) !important;
  background-size: 178px 178px !important;
  background-position: -10% center !important;
}

.test-user .ant-table {
  background: transparent;
}

/** -------------------- 重置antd start ------------------------------ */
.ant-layout {
  background-color: $content-background !important;
}

// 总览下的进度还原
.overview {
  .ant-progress-bg {
    height: 8px !important;
  }
}

/** -------------------- 重置antd end ------------------------------ */

// 用户引导
.ddpTooltipClass {
  .introjs-tooltip-header {
    .introjs-skipbutton {
      color: #000;
      font-size: 16px;
    }
  }
}

.ddpHighlightClass {
  // left: px !important;
  border: 1px solid #fff;
  border-radius: 0px;
}

.introjs-button {
  height: 32px;
  padding: 0px 15px;
  font-size: 14px;
  border-radius: 2px;
  line-height: 32px;
  box-sizing: content-box;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  outline: 0;
  background-color: #1890ff;
  text-shadow: none !important;
  border: none !important;
}

.introjs-disabled {
  color: #00000040;
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.introjs-button:focus,
.introjs-button:active,
.introjs-button:hover {
  outline: 0;
  text-decoration: none;
  background-color: #1890ff;
  color: #fff;
  border: none;
  box-shadow: none;
}

.introjs-disabled:focus,
.introjs-disabled:active,
.introjs-disabled:hover {
  color: #00000040;
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  border: none;
  box-shadow: none;
}

// 富文本
.ddpEditor {
  .w-e-text-container {
    height: initial !important;
    background-color: unset !important;
    color: unset !important;
    position: relative;

    .w-e-progress-bar {
      display: none;
    }

    .w-e-max-length-info {
      display: none;
    }

    .w-e-scroll {
      overflow: inherit !important;
      height: inherit !important;
    }
  }

  .w-e-text-container [data-slate-editor] {
    border-top: none !important;
    padding: 0 !important;
    margin: 0 !important;
    min-height: inherit !important;
  }

  .w-e-text-container [data-slate-editor] p {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1;
  }

  .w-e-bar-item button {
    width: auto !important;
  }
}

.dpdieit {
  height: 350px;
  border: 1px solid #e3e3e3;

  .w-e-text-container {
    background-color: #C93228;
  }

  .w-e-text-container [data-slate-editor] p {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1;
  }

  .w-e-text-placeholder {
    top: 0 !important;
  }
}

.goodNewBar {
  .w-e-toolbar {
    border: 1px solid #D5190D;
    border-bottom: none;
    border-radius: 5px 5px 0px 0px
  }
}

.ddprint-toolbar {
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 48px;
}

.fild_popover {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.paper_show {
  display: block;
}

.paper_hide {
  display: none;
}

.hidde_footer {
  .ant-modal-footer {
    display: none;
  }
}

.yldzda {
  z-index: 108100 !important;
  background: rgb(0 0 0 / 88%) !important;
}

.printModal {
  .ant-modal-body {
    padding: 0 !important;
  }
}