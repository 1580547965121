.qr_content {
  width: 210mm;
  // height: 297mm;
  margin: 0 auto;
}

.qr_content .qr_title {
  text-align: center;
  font-weight: bold;
  font-family: SimHei;
  font-size: 26pt;
  line-height: 1.5;
}
.qr_content .qr_title_0{
  text-align: center;
  font-weight: bold;
  font-family: SimHei;
  font-size: 16pt;
  line-height: 1.5;
  margin-bottom:11pt ;
}
.qr_content .qr_title_1{  
  font-weight: bold;
  font-family: SimHei;
  font-size: 15pt;
  line-height: 1.5;
  margin-bottom:10pt;
}
.qr_content .qr_title_2{    
  font-weight: bold;
  font-family: SimHei;
  font-size: 15pt;
  line-height: 1.5;
  margin-bottom:10pt;
}
.qr_content .qr_info{      
  margin-bottom:10pt;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}
.qr_content .qr_describe{    
  font-family: FangSong;
  font-size: 15pt;
  line-height: 1.5;    
  text-indent: 30pt;  
}
.qr_content p {
  padding: 0 !important;
  margin: 0 !important;  
}