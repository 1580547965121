@import "../../assets/style/theme.scss";

.tips {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 20px;
  font-weight: blod;
}

.tz {
  color: #1890ff;
}

.knows {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 22px;
}
