.introduceModalWrap {
  background: #f0f2f5 !important;

  .dialogBox {
    position: absolute;
    top: 20%;
    right: 2%;
    width: 330px;
    background: #fff;
    border-radius: 4px;

    h1 {
      display: flex;
      justify-content: space-between;
      padding: 20px 13px;
      margin-bottom: 0;
      border-bottom: 1px solid #F0F0F0;
      font-weight: normal;

      span {
        font-size: 16px;
        color: #333;

        &:nth-of-type(2) {
          font-size: 14px;
          color: #007FC7;
          cursor: pointer;
        }
      }
    }

    .dialogBoxCon {
      padding: 20px 35px;
      text-align: center;

      p {
        margin-bottom: 30px;
        text-align: left;
        font-size: 16px;
        color: #666;
      }
    }
  }

  :global {
    .ant-modal {
      width: 90% !important;
      top: 35px;
    }
  }
}