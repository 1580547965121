@import '../../assets/style/theme.scss';

// 导入相关的样式

.progressInfo {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0;
}

.progressWrapper {
  display: flex;
  padding-bottom: 8px;
  .progress {
    width: auto;
    flex: 1;
  }
}

.result {
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  max-height: 220px;
  overflow-y: auto;
}

.messageBox {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 8px;
  height: 126px;
  overflow-y: auto;

  .messageItem {
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0;
  }
}

.summary {
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 0;
}

.fail {
  color: #f5222d;
}

.success {
  color: #52c41a;
}

.importModal {
  .info {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  .formItem {
    margin-bottom: 16px;
  }

  .formItemLast {
    margin-bottom: 8px;
  }
}

.multipleUploadList {
  :global {
    .ant-upload-list {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}

.confirmModal {
  .checkGroup {
    width: 100%;
    padding-top: 8px;
    display: flex;
    flex-wrap: wrap;
  }
  .checkItem {
    padding-bottom: 1em;
    min-width: 208px;
  }
  .checkItemInfo {
    font-size: 12px;
    color: #faad14;
    padding-top: 4px;
  }
  :global {
    .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
      margin-left: 0;
    }
    .ant-checkbox-wrapper {
      > span:last-child {
        white-space: nowrap;
      }
    }
  }
}
.textfw {
  font-weight: bold;
  font-size: 20px;
  color: #000;
}
