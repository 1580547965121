// 主题色
$theme-color: #1890ff;
// 主题背景色
$theme-bg-color: #40a9ff;
// 主体宽度
$content-width: 1200px;
// 主体背景色
$content-background: #f5f5f5;
// 主体字号
$font-size: 16px;
// 顶部导航高度
$header-height: 48px;
