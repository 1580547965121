@import "../../assets/style/theme.scss";

.sortBox {
  margin: 16px 0;
  .sort {
    margin-bottom: 16px;
    font-size: 14px;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .handleIcon {
      cursor: all-scroll;
    }
  }
}
.btn {
  width: 400px;
  margin-left: 52px;
  color: rgba(0, 0, 0, 0.65);
}

.rowDragging {
  z-index: 99999999;
  .sort {
    width: 520px;
    border: 1px solid $theme-color;
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .handleIcon {
      color: $theme-color;
    }
  }
}
