@import "../../assets/style/theme.scss";

.handleBtnBox {
  margin: 0;
  .handleBtn {
    cursor: pointer;
    color: $theme-color;
  }
  .handleBtn + .handleBtn {
    margin-left: 16px;
  }
  .deleteBtn {
    color: red;
  }
}
.disabBtn{
  color: rgba(0, 0, 0, 0.25)!important;
  cursor: not-allowed !important;
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
} 
 