@import "../../assets/style/theme.scss";

.noDataBox{
    img{
        width: 140px;
        height: 110px;
    }
    p{
        font-size: 14px;
        color: #333;
        font-weight: 500;
        margin-top: 8px;
    }
}